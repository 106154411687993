import { observer } from "mobx-react-lite";
import { Channel } from "revolt.js";
import styled from "styled-components/macro";

import { Text } from "preact-i18n";

import { ChannelName } from "../../../controllers/client/jsx/ChannelName";
import {Tip} from "@revoltchat/ui";

const StartBase = styled.div`
    margin: 18px 16px 10px 16px;

    h1 {
        font-size: 23px;
        margin: 0 0 8px 0;
    }

    h4 {
        font-weight: 400;
        margin: 0;
        font-size: 14px;
    }
`;

interface Props {
    channel: Channel;
}

export default observer(({ channel }: Props) => {
    return (
        <StartBase>
            <h1>
                <ChannelName channel={channel} prefix />
            </h1>
            <h4 style={{ marginBottom: "10px" }}>
                <Text id="app.main.channel.start.group" />
            </h4>
            { (channel.channel_type == "DirectMessage" || channel.channel_type == "Group") && (
                <div style={{ marginBottom: "10px" }}>
                    <Tip palette={"primary"}>
                        Please be sure to follow our acceptable use policy and site rules at all times, 
                        including in private conversations.
                    </Tip>
                </div>
            )}
            
        </StartBase>
    );
});
