import styled from "styled-components/macro";

import StaffSvg from "../../../assets/badges/staff.svg";

import Tooltip from "../Tooltip";

enum Badges {
    Staff = 1
}

const BadgesBase = styled.div`
    gap: 8px;
    display: flex;
    flex-direction: row;

    img {
        width: 24px;
        height: 24px;
    }
`;

interface Props {
    badges: number;
    uid?: string;
}

export default function UserBadges({ badges, uid }: Props) {
    return (
        <BadgesBase>
            {(badges & Badges.Staff) && (
                <Tooltip
                    content={"Bishi Staff"}>
                    <img src={StaffSvg} width={20} height={20}/>
                </Tooltip>
            )}
        </BadgesBase>
    );
}
